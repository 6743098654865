import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { FieldsStateService } from '@dougs/fields/shared';
import { FileHttpService } from '../http/file.http';
import { AttachmentService } from '../services/attachment.service';

@Injectable({
  providedIn: 'root',
})
export class FileStateService extends StateService<void> {
  constructor(
    private readonly logger: LoggerService,
    private readonly fileHttpService: FileHttpService,
    private readonly attachmentService: AttachmentService,
    private readonly companyStateService: CompanyStateService,
    private readonly fieldsStateService: FieldsStateService,
  ) {
    super();
  }

  async uploadFiles(company: Company, fileType: string, companyKey: string, files: File | FileList): Promise<void> {
    const allowMultiple: boolean = 'length' in files;
    if (allowMultiple) {
      await this.uploadAttachments(company, files as FileList, companyKey, fileType);
    } else {
      await this.uploadAttachment(company, files as File, companyKey, fileType);
    }
    await this.fieldsStateService.refreshCompanyFields(company);
  }

  async deleteFiles(
    company: Company,
    attachment: Attachment,
    companyKey: string,
    allowMultiple: boolean,
  ): Promise<void> {
    await this.deleteAttachments(company, attachment, companyKey, allowMultiple);
    await this.fieldsStateService.refreshCompanyFields(company);
  }

  async convertFinalPrintZipToPdf(file: File): Promise<ArrayBuffer | null> {
    try {
      return await lastValueFrom(this.fileHttpService.convertFinalPrintZipToPdf(file));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async uploadAttachments(company: Company, files: FileList, modelCompanyKey: string, type: string): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) => lastValueFrom(this.fileHttpService.uploadFile(company, type, file))),
      );

      const updatedCompany: Company = this.attachmentService.addMultipleAttachmentFromKey(
        company,
        attachments,
        modelCompanyKey as keyof Company,
      );

      this.companyStateService.setCompanyState(updatedCompany);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAttachment(company: Company, file: File, modelCompanyKey: string, type: string): Promise<void> {
    try {
      const attachment: Attachment = await lastValueFrom(this.fileHttpService.uploadFile(company, type, file));

      const updatedCompany: Company = this.attachmentService.addSingleAttachmentFromKey(
        company,
        attachment,
        modelCompanyKey as keyof Company,
      );

      this.companyStateService.setCompanyState(updatedCompany);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteAttachments(
    company: Company,
    attachment: Attachment,
    modelCompanyKey: string,
    allowMultiple: boolean,
  ): Promise<void> {
    try {
      await lastValueFrom(this.fileHttpService.deleteFile(company, attachment));

      const updatedCompany: Company = allowMultiple
        ? this.attachmentService.removeMultipleAttachmentFromKey(company, attachment, modelCompanyKey as keyof Company)
        : this.attachmentService.removeSingleAttachmentFromKey(company, modelCompanyKey as keyof Company);

      this.companyStateService.setCompanyState(updatedCompany);
    } catch (e) {
      this.logger.error(e);
    }
  }
}
