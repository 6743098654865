export const ACCOUNTING_EXPORT_TYPES: { value: string; label: string }[] = [
  {
    value: 'journal',
    label: 'journaux comptables',
  },
  {
    value: 'balances',
    label: 'balances',
  },
  {
    value: 'framing',
    label: 'Cadrage e-commerce',
  },
  {
    value: 'amazon-framing',
    label: 'Cadrage Amazon',
  },
  {
    value: 'investments',
    label: 'tableau des immos',
  },
  {
    value: 'vatReceipts',
    label: 'justificatifs de TVA (zip)',
  },
];
