import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class FileHttpService {
  constructor(private readonly http: HttpClient) {}

  convertFinalPrintZipToPdf(file: File): Observable<ArrayBuffer> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/dev-tools/convert-final-print-zip-to-pdf`,
      formData,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  deleteFile(company: Company, file: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/attachments/${file.id}`,
    );
  }

  uploadFile(company: Company, fileType: string | undefined, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/attachments?filename=${encodeURIComponent(
        file.name,
      )}&type=${fileType}`,
      formData,
    );
  }

  uploadCSV(file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/files?filename=${encodeURIComponent(file.name)}`,
      formData,
    );
  }
}
