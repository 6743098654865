import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Establishment } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentsHttpService {
  constructor(private readonly http: HttpClient) {}

  getCompanyEstablishments(companyId: number): Observable<Establishment[]> {
    return this.http.get<Establishment[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/establishments`,
    );
  }

  updateEstablishment(companyId: number, establishment: Partial<Establishment>): Observable<Establishment> {
    return this.http.post<Establishment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/establishments/${establishment.id}`,
      establishment,
    );
  }

  deleteEstablishment(companyId: number, establishment: Partial<Establishment>): Observable<Establishment> {
    return this.http.delete<Establishment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/establishments/${establishment.id}`,
    );
  }

  createSecondaryEstablishmentTask(companyId: number, taskData: Partial<Establishment>): Observable<Establishment> {
    return this.http.post<Establishment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/actions/create-secondary-establishment-task`,
      taskData,
    );
  }

  createClosingSecondaryEstablishmentTask(
    companyId: number,
    establishment: Partial<Establishment>,
  ): Observable<Establishment> {
    return this.http.post<Establishment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/establishments/${establishment.id}/actions/create-closing-secondary-establishment-task`,
      establishment,
    );
  }

  deleteFile(companyId: number, establishment: Establishment, file: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/establishments/${establishment.id}/attachments/${file.id}`,
    );
  }

  uploadFile(
    companyId: number,
    establishment: Establishment,
    fileType: string | undefined,
    file: File,
  ): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/establishments/${establishment.id}/attachments?filename=${file.name}&type=${fileType}`,
      formData,
    );
  }
}
