import { Injectable } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  addMultipleAttachmentFromKey(company: Company, attachments: Attachment[], modelFileKey: keyof Company): Company {
    return {
      ...company,
      [modelFileKey]: company[modelFileKey]
        ? [...(company[modelFileKey] as Attachment[]), ...attachments]
        : [...attachments],
    };
  }

  addSingleAttachmentFromKey(company: Company, attachment: Attachment, modelFileKey: keyof Company): Company {
    return {
      ...company,
      [modelFileKey]: attachment,
    };
  }

  removeMultipleAttachmentFromKey(company: Company, attachment: Attachment, modelFileKey: keyof Company): Company {
    const attachments = (company[modelFileKey] as Attachment[])?.filter(
      (attachmentIterated: Attachment) => attachmentIterated.id !== attachment.id,
    );
    return {
      ...company,
      [modelFileKey]: attachments?.length > 0 ? attachments : null,
    };
  }

  removeSingleAttachmentFromKey(company: Company, modelFileKey: keyof Company): Company {
    return {
      ...company,
      [modelFileKey]: null,
    };
  }
}
