import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { JobApiService } from '@dougs/core/job';
import { JobDto } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import {
  FormalityStatus,
  GenerableDocument,
  GenerableDocumentError,
  InpiFormalityResult,
  InpiFormalityType,
  Session,
} from '@dougs/settings/dto';
import { GenerableDocumentHttpService } from '../http/generable-document.http';
import { InpiHttpService } from '../http/inpi.http';
import { SessionHttpService } from '../http/session.http';

interface SettingsState {
  session: Session;
  generableDocuments: GenerableDocument[];
}

@Injectable({
  providedIn: 'root',
})
export class SettingsStateService extends StateService<SettingsState> {
  readonly session$: Observable<Session> = this.select((state) => state.session);
  readonly generableDocuments$: Observable<GenerableDocument[]> = this.select((state) => state.generableDocuments);

  constructor(
    private readonly logger: LoggerService,
    private readonly sessionHttpService: SessionHttpService,
    private readonly generableDocumentHttpService: GenerableDocumentHttpService,
    private readonly inpiHttpService: InpiHttpService,
    private readonly jobApiService: JobApiService,
    private readonly window: Window,
  ) {
    super();
  }

  async refreshSession(userId: number): Promise<void> {
    try {
      this.setState({
        session: await lastValueFrom(this.sessionHttpService.getSession(userId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshGenerableDocuments(companyId: number): Promise<void> {
    try {
      this.setState({
        generableDocuments: this.getAndSetCacheState('generableDocuments', companyId)
          ? this.state.generableDocuments
          : await lastValueFrom(this.generableDocumentHttpService.getGenerableDocuments(companyId)),
      });
    } catch (e) {
      this.clearCache('generableDocuments');
      this.logger.error(e);
    }
  }

  async canGenerateDocument(companyId: number, type: string): Promise<GenerableDocumentError | null> {
    try {
      return await lastValueFrom(this.generableDocumentHttpService.canGenerateDocument(companyId, type));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async downloadAllDocuments(companyId: number): Promise<boolean> {
    try {
      await lastValueFrom(this.generableDocumentHttpService.downloadAllDocuments(companyId));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async sendFormality(companyId: number, inpiFormalityType: InpiFormalityType): Promise<InpiFormalityResult | null> {
    try {
      return await lastValueFrom(this.inpiHttpService.createFormality(companyId, inpiFormalityType));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async getFormalityStatus(companyId: number, formalityType: string): Promise<FormalityStatus | null> {
    try {
      return await lastValueFrom(this.inpiHttpService.getFormalityStatus(companyId, formalityType));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
  async generateDocument(companyId: number, selectedDocument: string): Promise<void> {
    try {
      const res: JobDto | { googleDocUri: string } = await lastValueFrom(
        this.generableDocumentHttpService.generateDocument(companyId, selectedDocument),
      );
      if ('googleDocUri' in res) {
        this.window.open(res.googleDocUri, '_blank');
      } else {
        const job: JobDto = await lastValueFrom(this.jobApiService.handleJob(res));
        const result = this.jobApiService.getLastInfo(job)?.result;
        this.window.open(result?.url, '_blank');
      }
    } catch (e) {
      this.logger.error(e);
    }
  }
}
