import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { JobDto } from '@dougs/core/job-dto';
import { GenerableDocument, GenerableDocumentError } from '@dougs/settings/dto';

@Injectable({
  providedIn: 'root',
})
export class GenerableDocumentHttpService {
  constructor(private readonly http: HttpClient) {}

  getGenerableDocuments(companyId: number): Observable<GenerableDocument[]> {
    return this.http.get<GenerableDocument[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/generable-documents`,
    );
  }

  canGenerateDocument(companyId: number, type: string): Observable<GenerableDocumentError> {
    return this.http.get<GenerableDocumentError>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/generable-documents/${type}/can-generate`,
    );
  }

  downloadAllDocuments(companyId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/files/actions/download?companyId=${companyId}`,
      {},
    );
  }

  generateDocument(companyId: number, selectedDocument: string): Observable<JobDto | { googleDocUri: string }> {
    return this.http.get<JobDto | { googleDocUri: string }>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/generable-documents/${selectedDocument}/generate`,
    );
  }
}
