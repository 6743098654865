import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Session } from '@dougs/settings/dto';

@Injectable({
  providedIn: 'root',
})
export class SessionHttpService {
  constructor(private readonly http: HttpClient) {}

  getSession(userId: number): Observable<Session> {
    return this.http.get<Session>(`${AppConfig.settings.legacyApiServerUrl}/users/${userId}/sessions`);
  }
}
