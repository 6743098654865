import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Establishment } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { EstablishmentsHttpService } from '../http/establishments.http';

interface EstablishmentState {
  establishments: Establishment[];
}

@Injectable({
  providedIn: 'root',
})
export class EstablishmentsStateService extends StateService<EstablishmentState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly establishmentsHttpService: EstablishmentsHttpService,
  ) {
    super();
  }

  readonly establishments$: Observable<Establishment[]> = this.select((state) => [...state.establishments].sort());

  async refreshEstablishments(companyId: number): Promise<void> {
    try {
      this.setState({
        establishments: await lastValueFrom(this.establishmentsHttpService.getCompanyEstablishments(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateEstablishment(companyId: number, establishment: Partial<Establishment>): Promise<Establishment | null> {
    try {
      const establishmentUpdated: Establishment = await lastValueFrom(
        this.establishmentsHttpService.updateEstablishment(companyId, establishment),
      );

      this.setState({
        establishments: this.state.establishments.map((establishmentIterated) =>
          establishmentIterated.id === establishmentUpdated.id ? establishmentUpdated : establishmentIterated,
        ),
      });

      return establishmentUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async deleteEstablishment(companyId: number, establishment: Partial<Establishment>): Promise<boolean> {
    try {
      await lastValueFrom(this.establishmentsHttpService.deleteEstablishment(companyId, establishment));

      this.setState({
        establishments: this.state.establishments.filter(
          (establishmentIterated) => establishmentIterated.id !== establishment.id,
        ),
      });

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async createSecondaryEstablishmentTask(companyId: number, taskData: any): Promise<boolean> {
    try {
      await lastValueFrom(this.establishmentsHttpService.createSecondaryEstablishmentTask(companyId, taskData));

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async createClosingSecondaryEstablishmentTask(
    companyId: number,
    establishment: Partial<Establishment>,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.establishmentsHttpService.createClosingSecondaryEstablishmentTask(companyId, establishment),
      );

      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async uploadFile(establishment: Establishment, modelFileKey: keyof Establishment, file: FileList): Promise<void> {
    if (file.length === 0) {
      return;
    }
    try {
      const attachment = await lastValueFrom(
        this.establishmentsHttpService.uploadFile(establishment.companyId, establishment, modelFileKey, file[0]),
      );

      this.setState({
        establishments: this.state.establishments.map((establishmentIterated: Establishment) =>
          establishmentIterated.id === establishment.id
            ? { ...establishment, [modelFileKey]: attachment }
            : establishmentIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
