import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { JobService } from '@dougs/core/job';
import { Job } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { JournalHttpService } from '../http/journal.http';

interface JournalState {
  movementedJournals: string[];
}

@Injectable({
  providedIn: 'root',
})
export class JournalStateService extends StateService<JournalState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly journalHttpService: JournalHttpService,
    private readonly jobService: JobService,
  ) {
    super();
  }

  readonly movementedJournals$ = this.select((state) => state.movementedJournals.sort());

  async refreshMovementedJournals(companyId: number): Promise<void> {
    try {
      this.setState({
        movementedJournals: this.getAndSetCacheState('journals', companyId)
          ? this.state.movementedJournals
          : await lastValueFrom(this.journalHttpService.getMovementedJournals(companyId)),
      });
    } catch (e) {
      this.clearCache('journals');
      this.logger.error(e);
    }
  }

  async exportAccountingJournal(
    company: Company,
    options: {
      fromDate: string;
      toDate: string;
      type: string;
      format: string;
      includeNonValidatedOperations: boolean;
      encoding: string;
      journals: string[];
    },
  ): Promise<Job | null> {
    try {
      return await lastValueFrom(
        this.jobService.handleJob(await lastValueFrom(this.journalHttpService.exportAccounting(company.id, options))),
      );
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }
}
