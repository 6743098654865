import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Job } from '@dougs/core/job-dto';

@Injectable({
  providedIn: 'root',
})
export class JournalHttpService {
  constructor(private readonly http: HttpClient) {}

  getMovementedJournals(companyId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/movemented-journals`,
    );
  }

  exportAccounting(
    companyId: number,
    options: {
      fromDate: string;
      toDate: string;
      type: string;
      format: string;
      includeNonValidatedOperations: boolean;
      encoding: string;
      journals: string[];
    },
  ): Observable<Job> {
    let params = new HttpParams()
      .append('fromDate', options.fromDate)
      .append('toDate', options.toDate)
      .append('type', options.type)
      .append('format', options.format)
      .append('includeNonValidatedOperations', options.includeNonValidatedOperations)
      .append('encoding', options.encoding)
      .append('frenchNumberFormat', options.encoding === 'utf8');

    options.journals.forEach((journal) => {
      params = params.append('journals[]', journal);
    });

    return this.http.get<Job>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting/export`, {
      params,
    });
  }
}
