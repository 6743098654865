import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { FormalityStatus, InpiFormalityResult, InpiFormalityType } from '@dougs/settings/dto';

@Injectable({
  providedIn: 'root',
})
export class InpiHttpService {
  private readonly dougsCreationBaseUrl = '/dougs-creation';
  constructor(private readonly http: HttpClient) {}

  createFormality(companyId: number, inpiFormalityType: InpiFormalityType): Observable<InpiFormalityResult> {
    return this.http.post<InpiFormalityResult>(
      `${AppConfig.settings.legacyApiServerUrl}/legal-inpi/${companyId}/create-formality`,
      { inpiFormalityType },
    );
  }

  getFormalityStatus(companyId: number, formalityType: string): Observable<FormalityStatus | null> {
    return this.http.get<FormalityStatus>(
      `${AppConfig.settings.legacyApiServerUrl}/legal-inpi/${companyId}/formality-status?formality=${formalityType}`,
      {},
    );
  }
}
